@import "https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap";

@bg-color: #444444;

@text-color: #ffffff;

@btn-color: #76b72d;

@btn-hover: #7fb542;

@accent-color: #92ce16;
/* stylelint-disable no-descending-specificity */
/* stylelint-disable no-kebab-case */

.custom-login-page {
  font-family: Montserrat, sans-serif;
  width: 100%;
  background-image: url("../../assets/background.jpg");
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0;

  .button {
    width: 100%;
    height: 50px !important;
    background: @btn-color !important;
    border: none;
    border-radius: 0.4rem;
    color: @text-color;
    font-size: 16px !important;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background: @btn-hover !important;
    }

    &:disabled {
      background: #9b9b9b !important;
      cursor: not-allowed;
      opacity: 1;
      color: #fff !important;
    }
  }

  .back-button {
    position: absolute;
    top: -2rem;
    left: 0;
    color: @text-color;
    background: unset !important;
    font-size: 1rem;
    padding: 8px;
    height: auto;
    width: auto;
    border: none;
    cursor: pointer;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      color: @btn-hover !important;
    }

    .anticon {
      font-size: 24px;
    }
  }

  .custom-login-container {
    background-color: @bg-color;
    width: 375px;
    min-height: 600px;
    padding: 3rem 1.2rem 2rem;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    border-radius: 25px;

    &__content {
      flex: 1;
      position: relative;
    }

    >div {
      display: flex;
      flex-direction: column;
    }
  }

  .ant-tabs-tab-btn:active {
    color: @text-color !important;
  }

  .ant-tabs {
    margin-bottom: 0;
    flex-shrink: 0;

    .ant-tabs-nav {
      &::before {
        border-bottom: none;
      }

      .ant-tabs-tab {
        color: rgb(255 255 255 / 50%);
        font-size: 14px;
        padding: 8px 0;
        margin: 0 16px;

        &.ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: @text-color;
          }
        }

        &:hover {
          color: @text-color !important;
        }
      }

      .ant-tabs-ink-bar {
        background: @btn-color;
      }
    }
  }

  .registration-form {
    color: @text-color;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    flex: 1 1;

    &__header {
      font-size: 1.375rem;
      font-weight: bold;
      text-align: center;
      color: @text-color;
      margin-bottom: 0;
    }

    >div {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    .ant-form-item {
      .ant-form-item-label {
        label {
          color: #fff;
          font-size: 14px;
          height: auto;
          line-height: 1.5;
        }
      }

      .ant-input,
      .ant-input-password .ant-input {
        background: rgb(255 255 255 / 10%);
        border: 1px solid rgb(255 255 255 / 20%);
        color: #fff;
        height: 50px;

        &::placeholder {
          color: rgb(255 255 255 / 50%);
        }

        &:hover,
        &:focus {
          border-color: rgb(255 255 255 / 30%);
        }
      }
    }

    input,
    .ant-input-password {
      height: 50px;
      background-color: #fff !important;
      color: #444 !important;
      border: none !important;
      border-radius: 0.4rem !important;
      padding: 0.5rem 1rem !important;
      width: 100%;
      font-size: 16px;

      input {
        background: none !important;
        padding: 0 !important;
      }
    }

    .ant-input-password {
      padding: 0 0.5rem !important;
      display: flex;
      align-items: center;

      .ant-input {
        height: 100% !important;
        padding: 0 0.5rem !important;
      }

      .ant-input-password-icon {
        color: #444;
        margin-top: 0;
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
  }

  .login-phone-form {
    &__mobile-spacer {
      flex: 1;
    }

    &__description {
      color: @text-color;
    }

    &__confirmation_button {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    &__confirmation_header {
      font-size: 1.375rem;
      font-weight: bold;
      text-align: center;
      color: @text-color;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 0.5rem;
      position: relative;
    }

    &__auto_sign {
      color: @text-color;

      .ant-checkbox-wrapper {
        color: @text-color;
      }

      .ant-checkbox {
        .ant-checkbox-inner {
          background-color: transparent;
          border-color: @text-color !important;
        }

        &.ant-checkbox-checked {
          .ant-checkbox-inner {
            background-color: @btn-color !important;
            border-color: @btn-color !important;
          }

          &:hover {
            .ant-checkbox-inner {
              background-color: @btn-hover !important;
              border-color: @btn-hover !important;
            }
          }

          &::after {
            border: none;
          }
        }

        &:hover {
          .ant-checkbox-inner {
            border-color: @btn-color !important;
            background-color: @btn-hover !important;
          }
        }
      }
    }
  }

  .login-phone-form,
  .login-sms-code-form {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    padding-top: 180px;

    &__logo {
      width: 230px;
      height: 80px;
      background-image: var(--logo-image);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 90px;
    }

    &__main-content {
      flex: 1;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }

    &__header {
      font-size: 1.375rem;
      font-weight: bold;
      text-align: center;
      color: @text-color;
      top: 200px;
      left: 0;
      right: 0;
    }

    &__ > div {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    .ant-form-item {
      width: 100%;
      margin-bottom: 1.5rem;
    }

    .ant-form-item:last-child {
      margin-top: 3rem;
      margin-bottom: 2rem;
    }

    input,
    .ant-input-password {
      height: 50px;
      background-color: #fff !important;
      color: #444 !important;
      border: none !important;
      border-radius: 0.4rem !important;
      padding: 0.5rem 1rem !important;
      width: 100%;
      font-size: 16px;

      input {
        background: none !important;
        padding: 0 !important;
      }
    }

    .ant-input-password {
      padding: 0 0.5rem !important;
      display: flex;
      align-items: center;

      .ant-input {
        height: 100% !important;
        padding: 0 0.5rem !important;
      }

      .ant-input-password-icon {
        color: #444;
        margin-top: 0;
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
  }

  .login-sms-code-form {
    text-align: center;

    &__description {
      color: rgb(255 255 255 / 70%);
      margin-bottom: 1.5rem;
    }

    &__actions {
      display: flex;
      flex-direction: column;
      margin-bottom: 2rem;
      gap: 1rem;

      .button {
        width: 100%;
        height: 40px !important;
        background: transparent !important;
        border: 1px solid rgb(255 255 255 / 20%) !important;
        margin: 0;
        font-size: 14px !important;

        &:hover {
          border-color: rgb(255 255 255 / 40%) !important;
          color: white !important;
          background-color: @btn-hover;
        }

        &:disabled {
          background: transparent !important;
          border-color: rgb(255 255 255 / 10%) !important;
          color: rgb(255 255 255 / 50%) !important;
        }
      }
    }
  }

  .code-input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: @text-color !important;

    .code-inputs {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      input {
        border: none;
        border-radius: 0;
        color: @text-color;
        background: none;
        -webkit-background-image: none;
        background-image: none;
        box-shadow: none;
        text-align: center;
        height: 2.4rem;
        width: 3rem;
        margin: 0 0.2rem;
        padding: 0;
        font-size: 1.4rem;
        border-bottom: 1px solid @text-color;

        &:focus {
          outline: none;
        }
      }
    }
  }

  /* stylelint-disable selector-pseudo-class-no-unknown */
  :global(.nav) {
    border: none;
  }

  :global(.nav-link) {
    background: transparent !important;
    color: @text-color;
    transition: none;

    &:focus,
    &:hover {
      border: none;
    }

    &.active {
      border: none;
      border-radius: 0;
      border-bottom: 2px solid @accent-color;
    }
  }

  /* stylelint-enable selector-pseudo-class-no-unknown */
}

@media only screen and (max-width: 600px) {
  .custom-login-page {
    .custom-login-container {
      width: 100%;
      min-height: 100vh;
      background: transparent;
      padding: 100px 10px;
    }

    &::before {
      display: none;
    }
  }
}

.agreement-form {
  color: #fff;
  line-height: 1.5;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  flex: 1;
  position: relative;

  &-container {
    padding: 0.2rem 0.5rem 0 1rem;
  }

  &__back-button {
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
    border: none;
    color: @text-color;
    font-size: 32px;
    cursor: pointer;
    padding: 0;
    width: auto;
    height: auto !important;
    margin: 0;
    line-height: 0;
    font-weight: 500;

    &:hover {
      background: transparent !important;
      color: @btn-color;
    }
  }
}

.signup-username,
.signup-first-name,
.signup-last-name,
.signup-patronymic,
.signup-email {
  margin-bottom: 24px;
}
