.code-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #fff !important;
}

.code-inputs {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  width: 100% !important;
}

.code-field {
  border: none !important;
  border-radius: 0 !important;
  color: #ffff !important;
  background: none !important;
  -webkit-background-image: none !important;
  background-image: none;
  box-shadow: none !important;
  text-align: center !important;
  height: 2.4rem !important;
  width: 3rem !important;
  margin: 0 0.2rem !important;
  padding: 0 !important;
  font-size: 1.4rem !important;
  border-bottom: 1px solid rgb(255 255 255 / 30%) !important;
  transition: all 0.2s ease-in-out !important;

  &::placeholder {
    color: rgb(255 255 255 / 30%) !important;
  }

  &:focus {
    outline: none !important;
    border-bottom-color: #fff !important;
    animation: pulse 0.3s ease-in-out;
  }

  &.filled {
    border-bottom-color: #fff !important;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

@primary-color: rgb(89,54,213);@border-radius-base: 5px;